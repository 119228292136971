import React from 'react';
// import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';
import * as queryString from 'query-string';

const AppImport = props => {
  const parsed = queryString.parse(props.location.search);

  if (parsed.reward) {
    window.location.href = `com.stars2apples://import?reward=${parsed.reward}`; // url link, not reward id
    return;
  }
  if (parsed.prop) {
    window.location.href = `com.stars2apples://import?prop=${parsed.prop}`; // url link, not prop id
    return;
  }

  return (
    <Layout bodyClass="page-contact">
      <div className="intro intro-small">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Redirecting to app...</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Call button={false} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AppImport;
